<template>
	<div class="main-contents">
		<div class="tit">
			기술인재 최종 정보
			<!--img class="special_rank" src="/images/admin/special_rank_btn.png" alt="스페셜 등급 관리" @click="special(tecMberSeq)"/-->
			<SelectComp type="checkbox" v-model="input.pronEvalYn" list="Y:하이프로 기술인재 평가완료,N:하이프로 기술인재 미평가" class="evl_check" @change="getList('1')"/>
		</div>
		<div class="search-box special">
			<SelectComp tagName="div" class="wd01" title="평균평가등급" v-model="input.evalGradeCd" cdId="PRO126" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd02" title="성별" v-model="input.gender" list="M:남,F:여" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="직원구분" v-model="input.empDivCd" cdId="PRO102" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="최종학력" v-model="input.schoolDivCd" cdId="PRO121" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd04" title="경력" v-model="input.careerTermCd" cdId="PRO107" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd05" title="기술등급_학력" v-model="input.resumeSchoolTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd05" title="기술등급_자격" v-model="input.resumeLicenseTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="참여구분" v-model="input.joinStatusCd" list="0:대기중,1:참여중,2:참여예정,3:인터뷰중" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd06" title="검색조건" v-model="input.searchType" :list="searchType" defValue="NAME"/>
			<InputComp :title="searchType[input.searchType]" v-model="input.searchTxt" @keydown.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin special">
				<colgroup>
					<!--col width="3%"-->
					<col width="3%">
					<col width="5%">
					<col width="5%">
					<col width="5%">
					<col width="13%">
					<col width="3%">
					<col width="3%">
					<col width="21%"><!--col width="18%"-->
					<col width="5%">
					<col width="5%">
					<col width="7%">
					<col width="6%">
					<col width="6%">
					<col width="7%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<!--th>선택</th-->
						<th>NO</th>
						<th><span class="ivl13">평</span>균<br/>평가등급</th>
						<th>회원명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>성별</th>
						<th>나이</th>
						<th>소속</th>
						<th>직원구분</th>
						<th>최종학력</th>
						<th>경력</th>
						<th>기술등급<br/>(학력기준)</th>
						<th>기술등급<br/>(자격기준)</th>
						<th>프로젝트<br/>참여구분</th>
						<th class="resume">기술인재 종합정보</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="list && list.length > 0">
						<!-- 최대 25건 조회 -->
						<tr v-for="(item, idx) in list" :key="item.mberSeq" :class="{new:isNew(item)}">
							<!--td class="radio">
								<SelectComp type="radio" v-model="tecMberSeq" :list="item.mberSeq+ ':'" />
							</td-->
							<td class="score"><div class="new" v-if="isNew(item)">new</div>{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx + 1}}</td>
							<td class="score">
								<grade-img type="TOT_EVAL_ADM" :grade="item.totEvalGradeCd" :specialYn="item.specialGradeYn" @click="special(item.mberSeq)" class="btn_cursor">
									<template v-slot:smark><div class="s-mark"><img src="/images/admin/s-mark.png" alt="스페셜마크"></div></template>
								</grade-img>
							</td>
							<td class="score">{{item.mberNm}}</td>
							<td class="score">{{item.mberSeq}}</td>
							<td class="score">{{item.loginId}}</td>
							<td class="score">{{item.gender | gender}}</td>
							<td class="score">{{item.birthYyyymmdd | birthYear}}</td>
							<td class="score">{{item.corpNm}}</td>
							<td class="score"><SelectComp type="text" :value="item.empDivCd" cdId="PRO102"/></td>
							<td class="score"><SelectComp  type="text" :value="item.schoolDivCd" cdId="PRO121"/></td>
							<td class="score">{{item.totWorkMonthCnt | months}}</td>
							<td class="score"><grade-img type="SCHOOL_TECH_ADM"  :grade="item.resumeSchoolTechGradeCd"/></td>
							<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="item.resumeLicenseTechGradeCd"/></td>
							<td class="score">
								<template v-if="item.joinNowYn    == 'Y'"><div class="state pf"></div>참 여 중</template>
								<template v-if="item.joinBeforeYn == 'Y'"><div class="state ep"></div>참여예정</template>
								<template v-if="item.interviewYn  == 'Y'"><img class="itv" src="/images/admin/itv_icon.png" alt="인터뷰마크"/>인터뷰중</template>
							</td>
							<td class="score resume"><div class="btn" @click="goDetail(item)">정보보기 &#9654;</div></td>
						</tr>
					</template>
					<tr v-else>
						<!-- 관련내용 없을 경우 -->
						<td colspan="16" class="none">등록된 회원이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";
export default {
	components: { pagingComp, gradeImg },
	data() {
		return {
			input : {
				evalGradeCd:'',
				gender:'',
				empDivCd:'',
				schoolDivCd:'',
				careerTermCd:'',
				resumeSchoolTechGradeCd: '',
				resumeLicenseTechGradeCd: '',
				joinStatusCd: '',
				searchType:'NAME',
				searchTxt: '',
				pronEvalYn:[],
			},
			tecMberSeq: '',
			list : {},
			pageInfo : {},
			searchType: {'NAME':'기술인재명', 'CORP':'소속회사명'},
		};
	},
	mounted(){
		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(key == 'pronEvalYn' && typeof this.$route.params.pronEvalYn == 'string'){
				this.input.pronEvalYn = this.$route.params.pronEvalYn.split(',')
			} else if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/mem/adm/tec/getTecMemberFinalList', this.input)
                .then(res => {
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },
		isNew(item){
			return item ? false : false;//new Date().add(-7, 'day') < this.$format.date(item.joinDate, 'date') ? true : false;
		},
		goPage(page){
			this.input.pageIndex = page;
			this.getList();
		},
		special(tecMberSeq){
			this.$.popup('/adm/mem/MEM912P01', { tecMberSeq })
				.then(res=> {
					if(res) this.getList() 
				});
		},
		goDetail(item){
			//console.log('goResume', item);
			var query = { tecMberSeq : item.mberSeq };
			var params = {
				caller : {
					name: this.$route.name,
					params: this.input,
				}
			}
			this.$router.push({name: 'MEM912M02', query, params });
		},
	},
};
</script>
